<template>
  <div>
    <page-header has-back></page-header>
    <div class="layout-main-body">
      <el-card shadow="never" class="card-alert">
        <el-alert type="info" show-icon :closable="false">
          <template slot="title">
            <ul>
              <li>应付金额是根据您的折扣进行计算，计算规则： 应付金额 = VIP年卡单价 x 折扣 x 数量</li>
            </ul>
          </template>
        </el-alert>
      </el-card>
      <el-card shadow="never" v-loading="pageLoading">
        <el-row style="padding-left: 200px">
          <el-col :span="22">
            <el-form :model="orderForm" :rules="orderRules" ref="orderForm" label-position="top" @submit.native.prevent>
              <el-form-item>
                <template v-slot:label>
                  <span class="form-label">充值帐户</span>
                </template>
                <div class="org-name">{{ currentAccount.name }}</div>
                <div class="org-equity">等级：{{ currentAccount.equityName }}，折扣：{{ currentAccount.discount }}</div>
              </el-form-item>
              <el-form-item prop="vipCardCount">
                <template v-slot:label>
                  <span class="form-label">充值数量</span>
                </template>
                <div class="combo-list">
                  <div class="combo-box" :class="{'active': currentGoods.vipCardCount === item.vipCardCount}"
                       v-for="(item,index) in goods" :key="index" @click="handleChangeGoods(item)">
                    <div class="combo-top"><span>{{ item.vipCardCount }}</span><span class="unit"> VIP年卡</span></div>
                    <div class="combo-money">¥{{ item.amount }}</div>
                  </div>
                </div>
              </el-form-item>
              <el-form-item>
                <el-input class="custom-input-wrapper" v-model.number="orderForm.vipCardCount"
                          @change="handleInputChange"
                          clearable
                          placeholder="自定义VIP年卡数量"></el-input>
              </el-form-item>
              <el-form-item>
                <span class="form-label">应付金额：</span>
                <span class="money" v-loading="moneyLoading">{{ formData.amount }}</span>
                <span class="unit">元</span>
              </el-form-item>
              <el-form-item>
                <el-button :disabled="payBtnDisabled" type="primary" @click="handlePay"
                           size="large" :loading="editLoading">
                  立即充值
                </el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </el-card>
    </div>
  </div>
</template>

<script>

import { AccountGoodsApi, AccountOrderApi, AccountApi } from '@/api'
import Dictionary from '@/utils/dictionary'

const goodsTemplate = [
  {
    vipCardCount: 10
  },
  {
    vipCardCount: 20
  },
  {
    vipCardCount: 30
  },
  {
    vipCardCount: 40
  },
  {
    vipCardCount: 50
  },
  {
    vipCardCount: 60
  },
  {
    vipCardCount: 80
  },
  {
    vipCardCount: 100
  }
]

export default {
  name: 'VipCardRecharge',
  data () {
    return {
      currentAccount: {},
      orderForm: {
        vipCardCount: ''
      },
      currentGoods: {},
      orderRules: {
        vipCardCount: [
          {
            type: 'number',
            pattern: /(^[1-9]\d*$)/,
            message: '只能输入正整数',
            trigger: 'blur'
          }
        ]
      },
      editLoading: false,
      goods: [],
      pageLoading: false,
      moneyLoading: false,
      formData: {
        vipCardCount: 0,
        amount: 0
      }
    }
  },
  computed: {
    payBtnDisabled () {
      return !this.currentGoods.vipCardCount && !this.orderForm.vipCardCount
    }
  },
  methods: {
    async initData () {
      this.pageLoading = true
      this.currentAccount = await AccountApi.getAccount()
      this.goods = await AccountGoodsApi.getGoodsList(goodsTemplate)
      this.pageLoading = false
    },
    handleChangeGoods (item) {
      this.currentGoods = item
      if (this.orderForm.vipCardCount) {
        this.orderForm.vipCardCount = ''
      }
      this.handleCalcAmount()
    },
    handleInputChange () {
      this.currentGoods = {}
      this.handleCalcAmount()
    },
    handleCalcAmount () {
      const vipCardCount = Number(this.orderForm.vipCardCount)
      if (vipCardCount > 0) {
        this.formData.vipCardCount = vipCardCount
        this.moneyLoading = true
        AccountGoodsApi.getGoodsList([{
          vipCardCount: vipCardCount
        }]).then(res => {
          this.moneyLoading = false
          this.formData.amount = res[0].amount
        })
      } else if (this.currentGoods.vipCardCount > 0) {
        this.formData.vipCardCount = this.currentGoods.vipCardCount
        this.formData.amount = this.currentGoods.amount
      }
    },
    handlePay () {
      if (!this.formData.vipCardCount) {
        this.$message.error('请选择或输入充值数量')
        return
      }
      this.editLoading = true
      AccountOrderApi.createOrder({
        vipCardCount: this.formData.vipCardCount,
        orderType: Dictionary.amsOrderType.rechargeVipCard.code,
        orderDesc: '购买VIP年卡',
        orderRemark: `购买VIP年卡${this.formData.vipCardCount}张`
      }).then((res) => {
        this.editLoading = false
        this.$router.replace(`/account/order/pay?id=${res.id}`)
      }).catch(() => {
        this.editLoading = false
      })
    }
  },
  created: function () {
    this.initData()
  }
}
</script>

<style scoped lang="less">

.form-label {
  font-weight: 700;
  font-size: 16px;
  color: #606266;
}

.btn-recharge {
  width: 440px;
  background-color: #fe2c55;
  height: 60px;
  border: none;
  margin-top: 10px;
  font-size: 16px;
  font-weight: 600;
}

.combo-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  .combo-box {
    cursor: pointer;
    width: 211px;
    height: 80px;
    border-radius: 4px;
    margin-right: 20px;
    margin-bottom: 20px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    background: #f8f8f8;

    .combo-top {
      color: #222;
      font-size: 18px;
      line-height: 18px;
    }

    .combo-money {
      margin-top: 10px;
      font-size: 16px;
      line-height: 16px;
      color: #505050;
    }
  }

  .active {
    background-color: #fdf6ec;
    border: 1px solid #fdac1e;
  }
}

.custom-input-wrapper {
  width: 211px;
  margin: 20px 0;
}

.org-name {
  font-size: 14px;
}

.org-equity {
  color: #999999;
  font-size: 12px;
}

.money {
  color: #ff2d55;
  font-size: 30px;
  line-height: 30px;
  margin-left: 4px;
}

.unit {
  font-size: 16px;
  color: #606266;
  margin-left: 4px;
}
</style>
